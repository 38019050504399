/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onClientEntry = () => {
  const link = document.createElement("link")
  link.href =
    "https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap"
  link.rel = "stylesheet"

  document.head.appendChild(link)

  const script = document.createElement("script")
  script.id = "helpspace-widget-script"
  script.type = "text/javascript"
  script.setAttribute("data-auto-init", "true")
  script.setAttribute("data-token", process.env.GATSBY_HELPSPACE_TOKEN)
  script.setAttribute("data-client-id", process.env.GATSBY_HELPSPACE_CLIENT_ID)
  script.setAttribute("data-widget-id", process.env.GATSBY_HELPSPACE_WIDGET_ID)
  script.setAttribute("data-cfasync", "false")
  script.src = process.env.GATSBY_HELPSPACE_SRC
  script.async = "true"

  setTimeout(() => {
    document.head.appendChild(script)
  }, 1000)
}
